.poweredby {
    text-decoration: none;
    color: var(--theme-color-dark);
    font-weight: 600;
    font-size: 15px;
}

footer {
    /* border-bottom: 10px solid var(--color-theme); */
    border-bottom: unset !important;
    height: auto;
}

.payment-icons li {
    list-style: none;
    display: inline-block;
    margin-right: 5px;
}

.payment-icons svg {
    height: 40px;
    width: 50px;
}

.powered-by {
    width: 65px;
}

.footer-nav {
    list-style-type: none;
    display: flex;
    gap: 20px;
    padding-left: 0;
    margin-bottom: 0;
}

.footer-nav li a {
    color: var(--theme-color-white);
    text-decoration: none;
    transition: all ease-in-out 0.3s;
}

/* .footer-nav li a:hover {
  color: var(--color-theme);
  text-decoration: none;
} */
.footer-nav li a .fssai-logo {
    width: 65px;
    height: auto;
}

.footer-logo {
    width: 150px;
}

@media screen and (max-width: 768px) {
    .footer-section {
        height: auto;
        border-bottom: unset !important;
    }

    /* .footer-nav li a {
    color: var(--theme-text-black) !important;
  } */

    .footer-nav {
        list-style-type: none;
        display: flex;
        gap: 10px;
        padding-left: 0;
        margin-bottom: 0;
        flex-direction: column;
        text-align: left;
        margin-top: 0 !important;
    }
}

/* ********** Theam Footer ************ */
@media (max-width: 1600px) {

    .footer-section .f-left,
    .footer-section .f-right {
        width: 300px;
    }
}

@media (max-width: 1366px) {

    .footer-section .f-left,
    .footer-section .f-right {
        display: none;
    }
}

.event-section .section-footer .nav {
    text-align: center;
    display: block;
}

.event-section .section-footer .nav .nav-item {
    display: inline-block;
}

.amenities-section .single-amenities {
    margin-bottom: 30px;
}

.amenities-section {
    padding-bottom: 100px;
}

.footer-section .info-box {
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    /* .footer-section .copyright-text {
    margin-top: 92px;
  } */
}

@media (max-width: 575px) {
    .event-section .section-footer .nav .nav-item {
        margin-right: 10px;
    }
}

/*  Footer Section Start */
.footer-section {
    /* padding: 25px 0; */
    padding: 15px 0;
    position: relative;
    /* background: var(--color-theme); */
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url(../../../assets/img/footer-bg.jpg);
    /* background-image: url(../../assets/banner/bannerbg.png); */
    background-size: cover;
    overflow: hidden;
}

.footer-section .shape {
    position: absolute;
    top: -2px;
    left: 0px;
    width: 100%;
}

.footer-section .f-left {
    position: absolute;
    left: 0px;
    bottom: 50px;
}

.footer-section .f-right {
    position: absolute;
    right: 0px;
    bottom: 50px;
}

.footer-section .top-area {
    text-align: center;

    align-items: center;
    display: flex;
    flex-direction: column;
}

.footer-section .top-area .logo {
    display: inline-block;
}

.footer-section .top-area .logo img {
    max-width: 190px;
}

.footer-section .footer-social-links .label {
    display: inline-block;
    font-weight: 600;
    color: #fff;
}

.footer-section .footer-social-links ul {
    display: inline-block;
    text-align: right;
    padding-left: 0;
}

.footer-section .footer-social-links ul li {
    margin-left: 0;
    display: inline-block;
}

.footer-section .footer-social-links ul li a {
    border-radius: 50%;
    background: none;
    display: block;
    color: #fff !important;
    line-height: 42px;
    text-align: center;
    transition: all ease 0.3s !important;
    padding: 5px !important;
}

.footer-section .footer-social-links ul li a svg {
    font-size: 1.5rem !important;
    /* width: 60px;
  height: 60px; */
    padding: 0 !important;
    margin-top: 0 !important;
}

.footer-section .footer-social-links ul li a .social-icon {
    transition: all ease 0.3s !important;
}

.footer-section .footer-social-links ul li a {
    background: var(--color-theme);
    color: var(--theme-color-white);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

/* .footer-section .footer-social-links ul li a .social-icon:hover {
  background: #fff;
  color: var(--color-theme);
  border-radius: 50%;
  font-size: 40px !important;
} */
.fssai-icon {
    width: 50px !important;
    margin-bottom: 1rem;
}

.social-icon {

    /* padding: 10px !important; */
    /* margin-top: 10px; */
}

.footer-section .newslater-wrapper {
    text-align: center;
    margin-bottom: 60px;
}

.footer-section .newslater-wrapper .newslater-form {
    margin-top: 35px;
    position: relative;
}

.footer-section .newslater-wrapper .newslater-form input {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0px 160px 0px 30px;
    height: 60px;
    color: #fff;
}

.footer-section .newslater-wrapper .newslater-form input::placeholder {
    color: #fff;
}

.footer-section .newslater-wrapper .newslater-form button {
    position: absolute;
    width: auto;
    height: 60px;
    color: #fff;
    font-size: 22px;
    line-height: 44px;
    border-radius: 50px;
    right: 0px;
    top: 0px;
    color: var(--color-theme);
    border: 0px;
    padding: 0px 30px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.footer-section .info-box {
    text-align: center;
}

.footer-section .info-box .icon {
    margin-bottom: 25px;
}

.footer-section .info-box .icon img {
    height: 60px;
}

.footer-section .info-box p {
    color: #fff;
    margin-bottom: 0px;
}

.footer-section .copyright-text {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 10px;
    padding-bottom: 0;
    color: #fff;
}

.footer-section .copyright-text a {
    color: #fff;
}

.footer-img-box {
    width: 200px;
    height: 200px;
}

.footer-img-box img {
    width: 100%;
    height: 100%;
}

.footer-bottom-company-name {
    /* padding-bottom: 1rem; */
    /* background: var(--btn-hover-color); */
    background: var(--color-theme);
}

.footer-bottom-company-name img {
    width: 100px;
    margin-left: 10px;
}

.top-footer-links {
    background: var(--color-theme) !important;
}

.top-footer-links li a {
    color: var(--theme-color-white) !important;
}



.four-card-section .four-card {
    background: #fec100;
    padding: 10px;
    /* padding-bottom: 10px; */
    text-align: center;
    border-radius: 10PX;
    border: 1px solid #fff;
    /* display: flex;
    align-items: center;
    gap: 10px; */
}

.four-card-section .four-card img{
    width: 20px;
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}
.four-card-section .four-card a {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon-img {
    display: none;
    /* margin-bottom: 1rem; */
}



.four-card-section h6 {
    margin: 0;
    color: #000;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.four-card-section .whatsapp a {
    color: #000;
    text-decoration: none;
}

.four-card-section .online-order a {
    color: #000;
    text-decoration: none;
}

.four-card-section .four-card .col-lg-2 a {
    color: #000;
    text-decoration: none;
}



@media (max-width: 992px) {
    .footer-bottom-company-name {
        padding-bottom: 5.5rem !important;
    }
}

@media (max-width: 568px) {
    .footer-bottom-company-name {
        padding-bottom: 5rem !important;
    }
}

.footer-adress-fs {
    font-size: 1.2rem !important;
}