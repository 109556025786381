.bg-light {
  background-color: var(--body-bg-color) !important;
}

.btn-danger {
  background-color: var(--color-theme);
  color: #000 !important;
}

.qty-btn-color {
  color: var(--color-theme);
}

.item-category {
  text-align: center;
  margin-right: 10px;
}

.item-name {
  margin-bottom: 20px;
  padding: 5px 0;
  /* font-weight: 600; */
  text-align: center;
  color: var(--theme-text-black);
}

.item-image {
  border-radius: 10px;
  overflow: hidden;
}

.menulistquickcategory .item-image {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.menulistquickcategory .item-image img {
  width: 50px;
  height: auto;
}

.offer-image {
  border-radius: 10px;
  overflow: hidden;
}

.offer-image-inner-banner {
  border-radius: 10px;
  overflow: hidden;
  height: 400px;
  width: 100%;
}

.offer-image-inner-banner img {
  border-radius: 10px;
  overflow: hidden;
  height: 100% !important;
  width: 100% !important;
}

.react-multiple-carousel__arrow {
  background: #fff !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px) !important;
  bottom: 50%;
  transform: translateY(0%);
  box-shadow: 2px 2px 2px #999;
}

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
  bottom: 50%;
  transform: translateY(0%);
  box-shadow: 2px 2px 2px #999;
}

.react-multiple-carousel__arrow::before {
  color: #000 !important;
}

.accordion-button {
  font-weight: 600;
}

.accordion-button:focus,
.form-control:focus {
  box-shadow: none !important;
  border-color: #eee !important;
}

.btn-whatsappcall {
  background-color: #25d366;
  padding: 10px;
  border-radius: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-call {
  background-color: #b3d325;
  padding: 10px;
  border-radius: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-facebook {
  background-color: #4267b2;
  padding: 10px;
  border-radius: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-instagram {
  background-color: #c13584;
  padding: 10px;
  border-radius: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-youtube {
  background-color: #ff0000;
  padding: 10px;
  border-radius: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-grub {
  background-color: var(--color-theme);
  padding: 10px;
  border-radius: 50px;
  height: 38px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color-white) !important;
}

.btn-call .btn-icon,
.btn-whatsappcall .btn-icon,
.btn-instagram .btn-icon,
.btn-youtube .btn-icon {
  color: #fff;
  font-size: 20px;
}

.btn-facebook .btn-icon {
  color: #fff;
  font-size: 14px;
}

.btn-call .btn-icon.rotate {
  transform: rotate(-35deg);
}

.offer-banner {
  margin-top: 20px;
}

.offcanvas .offer-banner {
  margin-top: 0px;
}

.offer-banner .react-multiple-carousel__arrow {
  display: none;
}

.react-multi-carousel-list {
  padding-bottom: 25px;
}

.see-more,
.see-more:hover {
  text-decoration: none;
  color: var(--theme-text-black);
  cursor: pointer;
}

/* .see-more svg { */
/* color: var(--color-theme); */
/* } */

.card {
  border: 0;
}

.item-category-filter {
  border: 2px solid;
  border-radius: 10px;
  width: 100%;
  display: inline-block;
  height: 40px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-weight: 600; */
  gap: 5px;
  max-width: fit-content;
  cursor: pointer;
  background: transparent;
  font-size: .8rem;
}

.item-category-filter svg {
  font-size: .8rem;
}

.item-category-filter.veg {
  border-color: #dee2e6;
  /* color: var(--theme-color-veg); */
}

.item-category-filter.veg svg {
  color: var(--theme-color-veg);
}

.item-category-filter.all {
  padding: 0;
  border: none !important;
}

.item-category-filter.all svg {
  height: 70%;
  width: 100%;
  /* border-color: var(--color-theme) !important; */
  /* color: var(--color-theme) !important; */
  /* color: var(--color-theme) !important; */
  color: var(--theme-text-black);
  background: var(--color-theme);
  font-size: 1.8rem;
  margin-top: 1px;
  padding: 5px;
  border-radius: 5px;
}

/* .item-category-filter.all.active svg { */
/* color: var(--color-theme); */
/* color: #fff !important; */
/* } */

.item-category-filter.nonveg svg,
.item-category-filter.veg svg {
  border: 2px solid var(--theme-text-black);
  padding: 2px;
}

.item-category-filter.nonveg svg {
  border-color: var(--theme-color-nonveg);
}

.item-category-filter.veg.active,
.item-category-filter.nonveg.active,
.item-category-filter.vegan.active {
  background: #d7000019;
  color: var(--theme-text-black) !important;
  border-color: #d7000063 !important;
}

/* .item-category-filter.veg.active svg{
  border-color: var(--theme-color-veg) !important;
} */
/* .item-category-filter.nonveg.active svg, */
/* .item-category-filter.vegan.active svg { */
/* color: var(--theme-text-black); */

/* } */

/* .item-category-filter.nonveg.active svg,
.item-category-filter.veg.active svg,
.item-category-filter.vegan.active svg {
  border-color: var(--theme-color-white);
} */

/* .item-category-filter.nonveg.active {
  background: var(--theme-color-nonveg);
} */

.item-category-filter.nonveg {
  border-color: #dee2e6;
  border-color: #dee2e6;
}

.item-category-filter.nonveg svg {
  color: var(--theme-color-nonveg);
}

.item-category-filter.vegan {
  border-color: var(--theme-color-vegan);
  color: var(--theme-color-vegan);
}

.item-category-filter.nonveg.filter {
  border-color: var(--color-theme);
  color: var(--color-theme);
  padding: 0 10px;
}

.item-category-filter.nonveg.filter svg {
  font-size: 24px;
  color: var(--color-theme);
}

.search-box {
  position: relative;
  padding-right: 0px;
}

.search-box .form-control {
  min-height: 40px !important;
  height: 40px !important;
}

.search-icon {
  position: absolute;
  bottom: 50%;
  right: 3%;
  transform: translateY(50%);
  font-size: large;
  color: var(--color-theme);
}

.shadow-search {
  box-shadow: 2px 2px 12px #eee;
}

.theme-red {
  color: var(--color-theme);
}

.react-multi-carousel-dot button {
  border-color: var(--color-theme) !important;
  background: transparent !important;
}

.react-multi-carousel-dot--active button {
  background: var(--color-theme) !important;
}

.react-multi-carousel-track {
  padding-bottom: 1rem;
}

.item-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
}

.item-card-wrapper {
  margin-bottom: 30px;
}

.item-card-wrapper:nth-last-child(1),
.item-card-wrapper:nth-last-child(2) {
  margin-bottom: 0px;
}

.item-card-wrapper:nth-child(3n - 1) {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.add-to-cart-btn {
  padding: 0.25rem 1rem !important;
}

/* .item-card:after {
  content: "";
  border-right: 1px solid #000;
  height: 100px;
  margin-left: 20px;
} */

.item-card img {
  border-radius: 10px;
}

.recipe-img {
  width: 170px !important;
  height: 170px !important;
  object-fit: cover;
}

.recipe-best {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.best-seller {
  background-color: chocolate;
  border-radius: 4px;
  padding: 0 4px;
  color: var(--theme-color-white);
  text-transform: capitalize;
}

.egg-recipe {
  background-color: maroon;
  border-radius: 4px;
  padding: 0 4px;
  color: var(--theme-color-white);
  text-transform: capitalize;
}

.customisable {
  background-color: #0aa3c2;
  border-radius: 4px;
  padding: 0 4px;
  color: var(--theme-color-white);
  text-transform: capitalize;
}

.recipe-name {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.recipe-name a {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: var(--theme-text-black);
  margin-top: 10px;
}

.recipe-price {
  margin-bottom: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.recipe-price svg {
  font-size: 13px;
}

.recipe-category {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
}

.recipe-category span {
  /* font-style: italic; */
  color: var(--text-light-black);
}

.recipe-description {
  margin-bottom: 5px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recipe-description.special-offer {
  font-size: 14px;
}

.recipe-description span {
  cursor: pointer;
}

.recipe-type.recipe-type {
  width: 20px;
  border-radius: 0;
}

.mild-chilli {
  font-size: 20px;
  color: green;
}

.medium-chilli {
  font-size: 20px;
  color: orangered;
}

.hot-chilli {
  font-size: 20px;
  color: red;
}

.star-rating {
  color: gold;
}

.wishlist-share {
  /* background-color: var(--color-theme); */
  color: var(--theme-text-black);
}

.accordion-button {
  border: 0px solid #eee;
}

.accordion-button:not(.collapsed) {
  color: var(--theme-text-black);
  background-color: var(--color-theme);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(100);
}

.quantity-control {
  display: flex;
  gap: 2px;
  align-items: center;
  background-color: var(--theme-color-white);
  color: var(--color-theme);
  border-radius: 5px;
  border: 1px solid var(--color-theme);
}

.quantity-btn {
  padding: 0px 5px !important;
  background-color: var(--theme-color-white);
  color: var(--theme-text-black);
  border-color: var(--theme-color-white);
  width: 20px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity-btn:first-child {
  border-radius: 4.5px 0 0 4.5px;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
}

.quantity-btn:last-child {
  border-radius: 0 4.5px 4.5px 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
}

.quantity-btn:hover,
.quantity-btn:focus,
.quantity-btn:active {
  background-color: var(--theme-color-white);
  color: var(--color-theme);
  border-color: var(--color-theme) !important;
  width: 20px;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: var(--theme-color-white);
  background-color: var(--color-theme);
  border-color: var(--theme-color-white);
}

.quantity-btn svg {
  font-size: 10px;
}

.form-check-input:checked {
  background-color: var(--color-theme);
  border-color: var(--color-theme);
}

.form-check-input:focus {
  border-color: var(--color-theme);
  box-shadow: none;
}

.form-inline {
  flex: 1;
}

.accordion-icon {
  margin-right: 8px;
  font-size: 18px;
}

.coupon-code {
  margin-bottom: 0;
  background-color: #f3f3f3;
  border: 3px dotted var(--color-theme);
  padding: 0 5px;
  cursor: copy;
}

.coupon-code-text {
  color: red;
}

.menu-bars {
  font-size: 30px;
  cursor: pointer;
}

.flex-div {
  flex: 1;
}

.modal-title {
  font-size: 1.2rem;
}

.payment-icons {
  padding-left: 0;
  margin-bottom: 0;
}

.food-type-filter-row {
  overflow-y: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
  gap: 15px;
  margin: 0;
  padding-bottom: 0px;
}

.sticky-search-bar {
  position: sticky;
  top: 56px;
  /* top: 0px; */
  z-index: 999;
  color: var(--color-theme);
  /* transition: all ease-in-out 1s; */
  animation: Searchbar 1s ease-out;
  background: #fff;
}

.sticky-search-bar .container-fluid {
  padding: 0;
}

@keyframes Searchbar {
  0% {
    background: #ffffff7c;
    transform: translateY(-100px);
  }

  100% {
    background: #ffffff;
    transform: translateY(0px);
  }
}

.restaurant-workhours {
  text-align: center;
  padding: 4px 10px;
  color: var(--theme-color-white);
}

.restaurant-workhours.open {
  background-color: var(--theme-color-vegan);
}

.restaurant-workhours.close {
  background-color: var(--theme-color-nonveg);
}

.restaurant-workhours.timing {
  background-color: var(--color-theme);
  position: relative;
  top: unset;
}

.addbtnorder {
  border-color: var(--color-theme);
  border-radius: 5px;
  outline: none;
  background-color: var(--theme-color-white);
  display: flex;
  gap: 5px;
}

.qty-add {
  color: var(--color-theme);
  font-weight: bold;
}

.rupee-sign-11 {
  font-size: 11px;
}

.saving {
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer !important;
}

.fixed-bottom {
  width: 50%;
}

.item-category-filter.nonveg.filter svg {
  border: none;
}

.required-label {
  color: var(--color-theme);
  border: 1px solid var(--color-theme);
  border-radius: 5px;
  text-transform: uppercase;
  padding: 5px;
  font-size: 10px;
}

.offcanvas.recipe-details {
  left: 30% !important;
  transform: translateX(-20%) !important;
  border-radius: 20px 20px 0 0;
  height: auto !important;
  padding-bottom: 6rem;
}

.offcanvas.recipe-details img {
  aspect-ratio: 1/1;
  object-fit: cover;
}

.share-item {
  position: absolute;
  top: 5px;
  right: 18px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
}

.rating-popup {
  position: absolute !important;
  z-index: 1;
  overflow: hidden;
}

.rating-popup.homepage {
  width: fit-content;
  top: 80%;
}

.progress {
  width: 235px;
}

.overflow-lg-hidden {
  overflow: hidden !important;
}

/* .add-ons-section { */
/* height: 450px; */
/* } */

.add-ons-section {
  max-height: 630px;
  overflow-x: hidden;
  /* overflow-y: auto; */
}

.add-ons-section::-webkit-scrollbar {
  width: 3.5px;
}

.add-ons-section::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.add-ons-section::-webkit-scrollbar-thumb {
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: var(--color-theme);
}

.add-ons-section::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

.add-ons-section::-webkit-scrollbar-thumb {
  background: var(--color-theme);
}

.add-ons-section .text-theme {
  color: var(--color-theme);
}

.add-ons-section .sp-lvl {
  margin-bottom: 9rem;
  flex-wrap: wrap;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.title-main-pt {
  padding-top: 3rem !important;
}

.spice-level-btn button {
  border: 1px solid var(--color-theme) !important;
  border-radius: 6px;
  background: transparent;
  overflow: hidden;
  padding: 0;
}

.spice-level-btn button label {
  color: var(--color-theme) !important;
  padding: 0 10px;
}

.spice-level-btn button.Active {
  border-width: 2px;
  background: var(--color-theme);
}

.spice-level-btn button.Active label {
  color: #fff !important;
}

.spice-level-btn button img {
  background: #fff;
  width: 30px;
  height: 30px !important;
  border-radius: 5px 0 0 5px;
}

button:focus-visible,
button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn:focus-visible {
  background: var(--color-theme) !important;
}

.oder-online-category {
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding-top: 1rem;
}

.oder-online-category::-webkit-scrollbar {
  width: 3.5px;
  height: 3px;
}

.oder-online-category::-webkit-scrollbar-track {
  background-color: var(--body-bg-color);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.oder-online-category::-webkit-scrollbar-thumb {
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: var(--body-bg-color);
}

.oder-online-category::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

/* .oder-online-category::-webkit-scrollbar-thumb { */
/* background: rgba(111, 133, 147, 0.1); */
/* } */

.oder-online-category .oder-online-category-card {
  max-width: 140px;
  min-width: 140px;
  display: inline-block;
  padding: 0.5rem 0.3rem;
  padding-bottom: 0;
}

.oder-online-category-title {
  text-transform: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.sticky-top-category-heading {
  margin-left: -1rem !important;
  width: 102%;
  top: 140px !important;
  padding: 10px 1rem;
  background: var(--theme-color-white);
  z-index: 1;
  border-radius: 5px 5px 0 0;
  padding: 0.5rem .7rem;
  margin-bottom: 2rem;
}

.sticky-top-category-heading h2 {
  font-size: 2rem;
  /* font-weight: bolder !important; */
}

.sticky-top-category-heading img {
  width: 70px;
}

/* .fixed-bottom { */
/* background-color: #fff; */
/* box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; */
/* width: 100%; */
/* display: flex;
    justify-content: flex-end;
    align-items: end; */
/* } */
.login-guest-modal h2 {
  font-family: inherit;
  font-weight: normal;
}

.login-guest-modal h3 {
  font-family: inherit !important;
  color: var(--color-theme) !important;
}

.login-guest-modal .btn-danger {
  padding: 0.5rem 1rem !important;
  font-size: 1rem;
}

.login-guest-modal .divider {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.login-guest-modal .divider p {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  padding: 0 5px;
  color: var(--color-theme);
  background: var(--theme-color-white);
}

.login-guest-modal .divider::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: var(--color-theme);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 0;
}

.order-online-sort {
  width: auto !important;
  padding-left: 0;
}

.order-online-sort .ant-select-selector {
  border: 2px solid #dee2e6 !important;
  height: 40px !important;
  margin-top: -4px;
  border-radius: 10px;
  width: 170px !important;
}

@media (min-width: 992px) {
  .py-lg-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media screen and (max-width: 1200px) {
  .item-card-wrapper:nth-child(3n - 1) {
    border: none;
  }

  .item-card-wrapper:nth-child(even) {
    border-left: 1px solid #dee2e6;
  }

  .item-card-wrapper:nth-child(odd) {
    border-left: 1px solid var(--theme-color-white);
  }

  .coupon-code-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) {
  .sticky-top-category-heading {
    padding: .5rem 0;
  }

  .sticky-top-category-heading h2 {
    font-size: 1.2rem;
    text-align: center;
    /* margin: 0 14px !important; */
  }

  .sticky-top-category-heading img {
    width: 60px;
  }

  .sticky-top-category-heading img:first-child {
    margin-left: 1rem;
  }

  .sticky-top-category-heading img:last-child {
    margin-right: 1rem;
  }

  .sticky-top-category-heading {
    top: 180px !important;
  }

  .category-box:hover {
    transform: scale(1) !important;
    margin-top: 0 !important;
  }

  .sp-lvl {
    flex-wrap: wrap;
    padding: 0 !important;
  }

  .spice-level-btn button {
    width: 150px;
    text-align: left;
  }

  .menu-bars.mobile-nav {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  footer {
    height: auto;
  }

  .footer-nav {
    padding: 10px 0;
  }

  /* .item-card {
    flex-direction: column;
  } */

  .fixed-bottom {
    width: auto;
  }

  .overflow-lg-hidden .overflow-y-scroll {
    overflow-y: auto !important;
  }

  .overflow-lg-hidden {
    overflow: auto !important;
  }

  .add-ons-section {
    height: auto;
    max-height: 100%;
    margin-bottom: 4rem;
  }

  .title-main-pt {
    padding-top: 7rem !important;
  }

  .item-card-wrapper:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .sticky-top-category-heading {
    top: 140px !important;
    margin-left: 0 !important;
    width: 100% !important;
    margin-bottom: .5rem;
  }

  .absolute-section {
    padding-bottom: 3rem !important;
  }

  /* p {
    text-align: justify;
  } */

  .section-highlighter {
    font-size: 16px;
    text-align: center;
  }

  .carousel-caption {
    width: 100%;
    left: 0;
    padding: 0 15px;
  }

  .carousel-caption h1 {
    font-size: 1.8rem;
    text-align: center;
  }

  .form-title {
    font-size: 16px;
  }

  .carousel-item img {
    height: 100vh;
    object-fit: none;
  }

  .main-title {
    text-align: center;
  }

  .why-choose-title {
    text-align: center;
  }

  .contact-info {
    justify-content: flex-start;
    padding-left: 40px;
  }

  .nav-link svg {
    width: 40px;
    height: auto;
    border-radius: 10px;
  }

  .social-media {
    border-top: 1px solid;
    padding: 10px;
    margin: 20px 10px;
  }

  .social-media-corner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-link svg.facebook {
    color: #1877f2;
  }

  .nav-link svg.linkedin {
    color: #0a66c2;
  }

  .element-img {
    display: none;
  }

  .element-img-mobile {
    display: block;
    opacity: 0.5;
  }

  .offer-banner .react-multi-carousel-list {
    overflow: hidden;
  }

  .item-name {
    margin-bottom: 10px;
    padding: 5px 0;
    font-weight: normal;
    text-align: center;
    font-size: 13px;
  }

  .item-card-wrapper:nth-child(even) {
    border-left: 0px solid #dee2e6;
  }

  .item-card-bottom {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 15px !important;
  }

  .item-card-wrapper {
    margin-bottom: 15px;
  }

  .item-card-wrapper:last-child .item-card-bottom {
    border-bottom: 0px solid #dee2e6;
    padding-bottom: 0px !important;
  }

  /* .item-card-wrapper {
    padding: 0px;
  } */
  .card-body {
    padding: 5px;
  }

  .shadow-search {
    box-shadow: none;
  }

  .search-box {
    position: relative;
    padding-right: 0px;
  }

  .oder-online-category .oder-online-category-card {
    max-width: 100px;
    min-width: 100px;
  }

  .recipe-category {
    margin: 0;
  }

  .recipe-img-wrapper {
    width: 130px;
    /* height: 200px; */
    overflow: hidden;
    border-radius: 10px;
    aspect-ratio: 1/1;
  }

  .recipe-img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    /* aspect-ratio: 1/1; */
  }

  .Item-image-Skeleton {
    height: 380px !important;
    width: 390px !important;
  }

  /* .item-card-wrapper {
    padding: 0px;
  } */
  .order-online-sort .ant-select-selector {
    margin: 0;
    width: 200px !important;
  }

  .order-online-sort .ant-select-selector,
  .item-category-filter {
    height: 100% !important;
    padding: 5px;
    max-width: 120px;
  }

  /* .category-title {
    font-size: 18px;
    font-weight: 400;
  } */

  footer {
    padding-bottom: 30px;
  }

  .sticky-search-bar {
    /* top: 100px; */
    /* top: 97px; */
    top: 0 !important;
    padding-top: 0 !important;
    border-radius: 0;
    padding: .5rem 0.75rem !important;
    background: var(--theme-color-white);
  }

  /* .sticky-search-bar .card { */
  /* border-radius: 0;
    padding: 0 0.75rem !important; */
  /* } */

  .restaurant-workhours h5 {
    font-size: 1rem;
  }

  /* .food-type-filter-row {
    padding-bottom: 15px;
  } */

  /* .offer-section.opening-hours { */
  /* margin-bottom: 170px; */
  /* } */

  .offcanvas.recipe-details {
    left: 0 !important;
    transform: unset !important;
    border-radius: 20px 20px 0 0;
  }

  .offer-banner {
    margin-top: 80px;
  }

  .rating-popup.homepage {
    top: 100%;
  }
}

@media (max-width: 568px) {
  .sticky-top-category-heading {
    top: 100px !important;
  }

  /* .oder-online-category {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: row;
    width: 100%;
    overflow-x: auto;
  } */

  /* [data-category-columns="3"] {
    grid-template-columns: repeat(3, 100px);
  }

  [data-category-columns="4"] {
    grid-template-columns: repeat(4, 100px);
  }

  [data-category-columns="5"] {
    grid-template-columns: repeat(5, 100px);
  }

  [data-category-columns="6"] {
    grid-template-columns: repeat(6, 100px);
  }

  [data-category-columns="7"] {
    grid-template-columns: repeat(7, 100px);
  }

  [data-category-columns="8"] {
    grid-template-columns: repeat(8, 100px);
  }

  [data-category-columns="9"] {
    grid-template-columns: repeat(9, 100px);
  }

  [data-category-columns="10"] {
    grid-template-columns: repeat(10, 100px);
  }

  [data-category-columns="11"] {
    grid-template-columns: repeat(11, 100px);
  }

  [data-category-columns="12"] {
    grid-template-columns: repeat(12, 100px);
  }

  [data-category-columns="13"] {
    grid-template-columns: repeat(13, 100px);
  }

  [data-category-columns="14"] {
    grid-template-columns: repeat(14, 100px);
  }

  [data-category-columns="15"] {
    grid-template-columns: repeat(15, 100px);
  }

  [data-category-columns="16"] {
    grid-template-columns: repeat(16, 100px);
  }

  [data-category-columns="17"] {
    grid-template-columns: repeat(17, 100px);
  }

  [data-category-columns="18"] {
    grid-template-columns: repeat(18, 100px);
  }

  [data-category-columns="19"] {
    grid-template-columns: repeat(19, 100px);
  }

  [data-category-columns="20"] {
    grid-template-columns: repeat(20, 100px);
  } */

  /* .oder-online-category .oder-online-category-card { */
  /* max-width: 100%;
    min-width: 100%;
    width: 100%; */
  /* aspect-ratio: 1/1; */
  /* height: 160px; */
  /* } */

  .add-to-cart-btn {
    padding: 0.5rem 1rem !important;
  }
}

.order-online-page .nav-banner-bg,
.order-online-page .logo-size {
  background: var(--theme-color-white);
}

.order-online-page .navbar-link-color a {
  color: var(--theme-color-white) !important;
}

.order-online-page .pc-sm-icon li a {
  color: var(--theme-color-white) !important;
}

.order-online-page .fixed-top.top-bar {
  position: static !important;
}

.order-online-page .order-online-btn.fixed-bottom {
  display: none !important;
}

.category-box {
  transition: 0.3s;
}

.category-box img {
  aspect-ratio: 1/1;
  object-fit: cover;
}

.category-box:hover {
  transform: scale(1.05);
  margin-top: -10px;
}

.spice-food-name-color {
  color: var(--color-theme) !important;
}

.spice-level-image-size {
  width: 30px !important;
  height: 25px !important;
}

.spice-level-instruction-text {
  border-bottom: 1px solid #ced4da !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.spice-level-modalposition2 {
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.order-online-page header.main-header .header-sticky.active {
  position: static !important;
  padding: 0 10px !important;
}

.order-online-page .order-online-btn {
  display: none !important;
}

.order-online-page header.main-header,
.order-online-page header.main-header .container {
  max-width: 100% !important;
  position: static !important;
}

.recipe-type-image {
  width: 13px;
}

.filters-drawer p {
  font-weight: bold;
}

.ant-drawer {
  z-index: 99999 !important;
}
.add-item-btn{
  border: 1px solid var(--color-theme) !important;
  background: var(--btn-light-bg);
  color: var(--theme-text-black) !important;
  border-radius: 5px !important;
}
.add-item-btn:hover{
  border: 1px solid var(--color-theme) !important;
  background: var(--color-theme);
  color: var(--theme-text-black) !important;
  border-radius: 5px !important;
  transition: 0.9s;
}