@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  /* --color-theme: #d70000; */
  --color-theme: #fec100;
  --color-white: #ffffff;
  --theme-color-dark: #333333;
  --linkedin-blue: #0072b1;
  --theme-color-white: #ffffff;
  --theme-highlight-text: #20395c;
  --theme-bg-light: #eeeeee;
  --theme-text-black: #343b4b;
  --theme-color-veg: #178138;
  --theme-color-nonveg: #bc0612;
  --theme-color-vegan: #008000;
  /* --color-theme: #36034A; */
  --btn-hover-color: #fec100;
  --btn-light-bg:#fec10082;
  --text-light-black:#7e8495;
  /* --btn-hover-color: #eabf7e; */
}

html,
body {
  font-family: "Poppins", sans-serif;
  color: var(--theme-color-dark);
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
  scroll-padding-top: 4rem;
}

/*
h1 {
  font-size: 90px;
  line-height: 100px;
}

h2 {
  color: #36034A !important;
  display: block;
  font-size: 35px;
  line-height: 10px;
  margin-bottom: 13px;
  text-transform: uppercase;
  font-family: 'Allura', cursive !important;
  letter-spacing: 6px;
  font-weight: 800 !important;
}

h3 {
  font-size: 50px !important;
  line-height: 70px;
  font-weight: 700;
  font-family: "/", sans-serif !important;
  color: #000 !important;
} */

/* p {
  font-size: 1.1rem ;
  color: #181919 ;
  line-height: 2rem ;
  font-family: "Josefin Sans", sans-serif;
  text-align: justify;
} */

a {
  color: var(--theme-color-dark);
  text-decoration: none !important;
}

.btn {
  padding: 0.8rem 1rem !important;
  border-color: var(--color-theme) !important;
}

.btn.btn-sm {
  padding: 0.25rem !important;
}

.btn:hover {
  background-color: var(--btn-hover-color) !important;
  border-color: var(--btn-hover-color) !important;
}

.btnicons {
  width: 20px;
}

.swiper-slide {
  height: auto !important;
}

.btn-close {
  filter: invert(0);
  opacity: 1 !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--color-theme) !important;
  opacity: .6 !important;
}

/* Disable zooming on Safari */
@media (max-width: 767px) {

  html,
  body {
    touch-action: manipulation !important;
  }
}

.main-route-box {
  touch-action: manipulation !important;
}

.btn-default {
  display: inline-block;
  font-family: var(--default-font) !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1em;
  color: var(--color-theme);
  background: transparent;
  text-align: start;
  padding: 16px 60px 16px 20px;
  border: 2px solid var(--color-theme);
  border-radius: 100px;
  transition: var(--transition);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-default::before {
  content: "\f061";
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 900;
  width: 36px;
  height: 36px;
  color: var(--theme-color-white);
  background-color: var(--color-theme);
  border: 1.5px solid var(--color-theme);
  border-radius: 50%;
  transition: var(--transition);
  transform: translate(-6px, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-default::after {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background: var(--color-theme);
  border-radius: 30px;
  transition: var(--transition);
}

.btn-default:hover {
  color: var(--theme-color-white);
}

.btn-default:hover::after {
  width: 110%;
  left: -10px;
}

.btn-default:hover::before {
  background-color: var(--primary-color);
  border-color: var(--color-theme);
  color: var(--color-theme);
}

.text-theme {
  color: var(--color-theme);
}

.page-header-box nav ol {
  display: none;
}

.design-two .menu-item-body h3 {
  font-weight: normal;
}