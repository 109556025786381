.customer-dashboard-card {
  position: relative;
  height: 100%;
}

.customer-dashboard-card .customer-dashboard-edit-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: transparent;
  font-weight: bold;
  font-size: 1.5rem;
}

.add-address-offcanvas .btn-close {
  -webkit-filter: invert(100%) !important;
  filter: invert(100%) !important;
}

.customer-dashboard-card .customer-dashboard-edit-btn svg {
  margin-top: 0px;
  color: var(--color-theme);
}

.order-list-view-box-no {
  max-width: 15%;
}

.order-list-view-box {
  max-width: 10%;
}

.customer-dashboard-card img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid #b9b7b7;
}

.cus-das-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.cus-das-btn p {
  width: fit-content;
  background-color: var(--color-theme);
  border-radius: 0.375rem;
  margin-bottom: 0;
  color: #fff;
  padding: 5px 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cus-das-btn svg {
  width: 32px;
  height: 40px;
  background-color: var(--color-theme);
  border-radius: 0.375rem;
  margin-top: 0px;
  color: #fff;
}

.frequently-order-card img {
  width: 100% !important;
  height: 300px;
}

.profile-box {
  background-color: var(--color-theme);
}

.profile-box-icon {
  background-color: #fff;
  color: var(--color-theme);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
  margin-bottom: 15px;
}

.profile-box-icon svg {
  height: 90px;
  width: 90px;
  position: absolute;
  left: 53%;
  top: 55%;
  transform: translate(-50%, -50%);
}

.order-list-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding: 10px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.order-list-view:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}

.order-list-view-name p {
  margin-bottom: 0;
}

.order-list-view {
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-theme);
}

.recent-order-icon,
.order-item-options {
  font-size: 24px;
  background: transparent !important;
  padding: 0;
  border: none !important;
}

.recent-order-icon:hover {
  border: none !important;
}

.recent-order-icon svg,
.order-item-options svg {
  color: var(--color-theme);
}

.text-danger {
  color: var(--color-theme) !important;
}

.text-failure-message {
  color: var(--theme-color-nonveg);
}

.height-fit {
  height: fit-content;
}

.logout-btn {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

@media screen and (max-width: 768px) {
  .order-list-view-main {
    overflow-x: auto;
  }

  .order-list-view {
    display: flex;
    width: 300%;
  }

  .order-list-view-box-no {
    max-width: fit-content;
  }

  .order-list-view-name p {
    margin-bottom: 1rem;
  }

  .recent-order-icon,
  .order-item-options {
    text-align: end;
  }

  .customer-dashboard-address-card {
    margin-right: 1rem;
  }

  .customer-dashboard-address-card .card-body,
  .recent-order-box .card-body {
    padding: 1rem !important;
  }
}