.logo {
    width: 105px;
}

.halal-icon {
    width: 32px;
    height: auto;
    cursor: default;
}

.back-button {
    font-size: 25px;
    color: var(--color-theme);
}

.menu-bars {
    color: darkslategray;
    font-size: 24px;
}

.top-bar {
    height: auto;
}

.rest-rating {
    border-radius: 10px;
    background-color: var(--theme-color-veg);
    width: 50px;
    height: 50px;
    overflow: hidden;
}

.rating-star {
    font-size: 12px;
    margin-top: -4px;
}

.rating-num {
    font-size: 12px;
}

.rest-rating p {
    margin-bottom: 0;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding-top: 0.1rem;
}

.rest-rating-reviews {
    height: auto;
    color: var(--theme-color-white);
    font-size: 10px;
    margin-bottom: 0;
    line-height: 12px;
    margin-right: 5px;
}

.rest-rating-total {
    color: var(--theme-color-white);
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
}

.rest-share {
    border-radius: 10px;
    background-color: var(--theme-bg-light);
    width: 50px;
    height: 50px;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    color: var(--theme-text-black);
}

.rest-share svg {
    font-size: 24px;
}

.rest-share p {
    font-size: 9px;
    padding-top: 7px;
}

.rest-rating-link,
.rest-share-link {
    text-decoration: none;
}

@media (max-width:850px) {
    .top-bar {
        height: auto;
    }
}

.dashboard-btn {
    color: var(--theme-color-white) !important;
    border-radius: 0;
}

.logo-size-sidebar {
    height: 120px;
}

.sidebar-phn-cross-btn .btn-close {
    font-size: 25px;
}

.sidebar-phn-cross-btn .offcanvas-header {
    padding-left: 5px;
}

.navbar-link-color-sidebar {
    padding-left: 0;
    font-size: 23px;
    line-height: 1.3rem;
}

.sidebar-payment-icon {
    box-shadow: 0px 0px 2px 0px #808080cf;
}

.amex-icon-size {
    background-color: #ffffff;
    width: 80px;
}

.sidebar-call-icon {
    color: var(--color-theme);
}


/* same link for navbar */
.navbar-link-color-online-order a {
    color: #000 !important;
}

.offcanvas-title .logo-size {
    transform: translateY(0);
}

.react-stars span {
    /* color: #ffffff !important; */
}

@media (max-width:992px) {
    .navbar-link-color-online-order {
        align-items: left !important;
        padding-left: 10px;
        /* font-size: 23px; */
        line-height: 0.8rem;
    }
}

@media (min-width:992px) {
    .navbar-link-color-online-order {
        align-items: center;
    }
}

/* New css */
.navabr-bg {
    background: var(--color-theme);
    height: 80px;
}

.nav-brand-size {
    justify-content: space-between;
    width: 100%;
    /* padding-right: 50px; */
}

@media (max-width:500px) {
    .nav-brand-size {
        justify-content: center !important;
    }
}

@media (max-width:800px) {
    .nav-brand-size {
        justify-content: space-between;
        width: 100%;
        padding-right: 0;
    }
}