.payment-succes-main{
    height: 102vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f3;
    margin-top: -2rem;
    padding-top: 2.7rem;
}
.payment-succes-icons{
    width: 300px;
}
.payment-succes-box h3{
    color: var(--color-theme) !important;
}
.payment-succes-box{
    background: var(--color-white);
    padding: 2rem;
    margin: auto;
    border-radius: 8px;
}

@media (max-width:568px) {
    .payment-succes-box{
        padding: 1rem;
    }
    .payment-succes-icons{
        width: 150px;
        margin-bottom: 1rem;
    }
    .payment-succes-main{
        background: var(--color-white);
    }
}